define("discourse/plugins/waze-templates/discourse/components/waze-copy-template-button", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DButton
    ...attributes
    @action={{this.copyTemplateToClipboard}}
    @icon="w-copy"
    @title="js.waze.templates.copy_template_button.description"
  />
  <wz-snackbar id={{this.snackbarId}}>{{i18n
      "waze.templates.copy_template_snackbar.description"
    }}</wz-snackbar>
  */
  {
    "id": "U5vFNuoO",
    "block": "[[[8,[39,0],[[17,1]],[[\"@action\",\"@icon\",\"@title\"],[[30,0,[\"copyTemplateToClipboard\"]],\"w-copy\",\"js.waze.templates.copy_template_button.description\"]],null],[1,\"\\n\"],[10,\"wz-snackbar\"],[15,1,[30,0,[\"snackbarId\"]]],[12],[1,[28,[35,2],[\"waze.templates.copy_template_snackbar.description\"],null]],[13]],[\"&attrs\"],false,[\"d-button\",\"wz-snackbar\",\"i18n\"]]",
    "moduleName": "discourse/plugins/waze-templates/discourse/components/waze-copy-template-button.hbs",
    "isStrictMode": false
  });
  class WazeCopyTemplateButton extends _component2.default {
    copyTemplateToClipboard() {
      const post = this.args.post;
      const copiedText = `[wzTemplate topic=${post.topic_id} post=${post.post_number}][/wzTemplate]`;
      navigator.clipboard.writeText(copiedText);
      const snackbar = document.getElementById(this.snackbarId);
      snackbar.showSnackbar();
    }
    static #_ = (() => dt7948.n(this.prototype, "copyTemplateToClipboard", [_object.action]))();
    get snackbarId() {
      return `template_copied_${this.args.post.id}`;
    }
  }
  _exports.default = WazeCopyTemplateButton;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, WazeCopyTemplateButton);
});